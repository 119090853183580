.image_content_card_main_div {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 450px;
}
.image_div {
  width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}
.image_div:hover {
  height: 400px;
  transition: all 5s;
}
@media only screen and (max-width: 768px) {
  .image_content_card_main_div {
    height: 400px;
  }
  .image_div {
    height: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .image_content_card_main_div {
    height: 400px;
  }
  .image_div {
    height: 300px;
  }
}
