.main_footer {
  background-color: black;
  padding-bottom: 1rem;
  margin-top: 2rem;
}
.footer {
  color: black;
  padding: 3rem 0;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.footer a {
  text-decoration: none;
  color: white;
}
.footer a:hover {
  color: #ff5733;
}
.hr {
  width: 100%;
}
.lastFooter {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}
.lastFooter a {
  text-decoration: none;
  color: white;
}
.footer_section_last{
  background-color: black;
}