.fontTitle {
  font-family: 'font-title', Montserrat,Arial,sans-serif;
}

.fontText {
  font-family: 'font-text', Montserrat,Arial,sans-serif;
}
.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.primary {
  color: black;
}

.secondary {
  color: white;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.gray {
  color: gray
}

.error {
  color: red
}

.darkerGray {
  color: #797979;
}

.isSemibold {
  font-weight: 400;
}

.isBold {
  font-weight: 700;
}

.isUppercase {
  text-transform: uppercase;
}

.percentage1 {
  color: #fe8485;
}

.percentage2 {
  color: #f4e26c;
}

.percentage3 {
  color: #85ecc1;
}
