.coverimage_container {
  height: 350px;
  overflow: hidden;
  position: relative;
}
.coverimage {
  height: 100%;
  width: 100%;
object-fit: cover;
}
.coverimage:hover {
  height: 115%;
  transition: all 4s;
}
