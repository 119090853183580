.underlineText {
  color: #f16434;
  padding-bottom: 1px;
  padding: 0.7rem 1rem;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  border: 1px solid tomato;
  border-radius: 0.2rem;
  width: fit-content;
  cursor: pointer;
}
.underlineText:hover {
  border: 1px solid white;
  background-color: #f16434;
  color: white;
}
/* .buttonComp{
  background-color: black;
  color: white;
}
.buttonComp:hover {
  border: 1px solid goldenrod !important;
  background-color: white;
  color: goldenrod;
  transition: all 1s;
} */
