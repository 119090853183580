.mainSliderDiv {
  height: 80vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.mainSliderDiv_img {
  position: absolute;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .mainSliderDiv {
    height: 80vh;
  }
}
@media only screen and (max-width: 600px) {
    .mainSliderDiv {
      height: 33vh;
    }
  }
